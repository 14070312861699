<script>
import CultureChart from "@/components/CultureChart.vue"
export default {
    components: {CultureChart },
    data() {
        return {
            labels: [
				'Red',
				'Blue',
				'Yellow'
			],
			datasets: [{
				label: 'My First Dataset',
				data: [300, 50, 100],
				backgroundColor: [
				'rgb(255, 99, 132)',
				'rgb(54, 162, 235)',
				'rgb(255, 205, 86)'
				],
				hoverOffset: 4
			}]
        }
    }
}
</script>

<template>
    <div class="main">
        <CultureChart />
    </div>
</template>
